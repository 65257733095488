<template>
  <Panel header="Filtros" :toggleable="true" collapsed>
    <div class="card px-4 py-5 md:px-6 lg:px-8">
      <div class="grid grid-nogutter align-items-center mt-3">
        <Dropdown :options="periodos" v-model="filters.period"
                  option-value="periodo" option-label="name"
                  placeholder="Período"
                  date-format="dd/mm/yy"
                  class="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full lg:w-18rem mr-0 lg:mr-4 text-900"></Dropdown>
        <Calendar v-model="filters.start" show-icon
                  :disabled="filters.period"
                  placeholder="Data Início"
                  date-format="dd/mm/yy"
                  class="flex-auto lg:flex-1 mb-3 lg:mt-0 w-10rem lg:w-10rem mr-0 lg:mr-4 text-900"></Calendar>
        <Calendar v-model="filters.end"
                  show-icon placeholder="Data Final"
                  class="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full lg:w-18rem mr-0 lg:mr-4 text-900"></Calendar>
        <Button v-ripple tabindex="0"
                @click.prevent="loadDashboard"
                icon="pi pi-search"
                class="cursor-pointer p-button-rounded p-button-success flex align-items-center mb-3 lg:mt-0 mr-2"></Button>

      </div>
    </div>
  </Panel>
  <div class="grid mt-3">
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Rendimentos</span>
            <div class="text-900 font-medium text-green-500 text-xl">
              {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(dashboard.income)}}
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-plus text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Despesas</span>
            <div class="text-900 font-medium text-orange-500 text-xl">
              - {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(dashboard.expenses)}}
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-minus text-orange-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Balanço do Período</span>
            <div class="text-900 font-medium text-xl text-green-500" v-if="lucro >= 0">
              <b>{{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(lucro)}}</b>
            </div>
            <div class="text-900 font-medium text-xl text-orange-500" v-if="lucro < 0">
              <b>{{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(lucro)}}</b>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-thumbs-up text-cyan-500 text-xl" v-if="lucro >= 0"></i>
            <i class="pi pi-thumbs-down text-orange-500 text-xl" v-if="lucro < 0"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Consultas Agendadas</span>
            <div class="text-900 font-medium text-xl">
              {{dashboard.schedulerQueryTotal}}
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-calendar-plus text-purple-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card h-25rem">
        <h5>Produtos com Baixa no Estoque</h5>
        <DataTable :value="dashboard.products" :rows="5" :paginator="true" responsiveLayout="scroll">
          <Column field="name" header="Produto" style="width:50%">
          </Column>
          <Column field="provider" header="Marca" style="width:35%"></Column>
          <Column field="amount" style="width:20%" class="text-center">
            <template #header>
              Quantidade
            </template>
            <template #body="slotProps">
              <Badge v-show="slotProps.data.amount === 0" value="0" class="p-badge-danger"></Badge>
              <Badge v-show="slotProps.data.amount > 0" :value="slotProps.data.amount" class="p-badge-danger"></Badge>
            </template>
          </Column>
          <Column style="width:15%">
            <template #body="slotProps">
              <Button icon="pi pi-search" type="button" class="p-button-text" @click="productFault(slotProps.data)"></Button>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="card h-26rem">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Aniversários do Mês</h5>
        </div>
        <div class="flex align-content-center align-items-center" v-if="dashboard.contacts.length < 1">
          <span>Nenhum aniversariante este mês</span>
        </div>
        <ul class="list-none p-0 m-0" v-if="dashboard.contacts.length > 0">
          <li class="pb-3 border-bottom-1 border-300 mt-3"
              v-for="contact of dashboard.contacts" :key="contact.id"
          >
            <div class="flex">
              <img src="@/assets/icons_birthday.png" class="mr-3" style="width: 45px; height: 45px" />
              <div class="md:mr-auto">
                <span class="block text-900 font-medium mb-1">Nome</span>
                <div class="text-600">{{ contact.name }}</div>
              </div>
              <div class="md:mr-8">
                <span class="block text-900 font-medium mb-1">Aniversário</span>
                <div class="text-600">{{ contact.birth }}</div>
              </div>
              <div class="flex flex-nowrap md:mr-3 align-items-center align-content-center">
                <a v-show="contact.instagram"  class="p-button-text p-button-plain p-button-rounded mr-1" :href="'https://www.instagram.com/' + contact.instagram" target="_blank">
                  <i class="pi pi-instagram text-purple-500" style="font-size: 2rem"></i>
                </a>
                <i v-show="!contact.instagram" class="pi pi-instagram text-gray-300" style="font-size: 2rem"></i>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <div class="card">
        <h5>Procedimentos Concluídos</h5>
        <div class="flex align-items-center justify-content-center">
          <Chart type="polarArea" :data="chartDataPolar" :options="chartOptions" height="280"/>
        </div>
      </div>
      <div class="card">
        <h5>Consultas por Status</h5>
        <div class="flex align-items-center justify-content-center">
          <Chart type="pie" :data="chartData" :options="lightOptions" height="280"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/service/DashboardService";
import moment from "moment";

export default {
  data() {
    return {
      dashboardService: null,
      dashboard: {
        income: 0,
        expenses: 0,
        schedulerFinishTotal: 0,
        schedulerCancelledTotal: 0,
        dashChartModels: [],
        contacts: []
      },
      chartData: {
        labels: ['CONCLUIDAS','CANCELADAS'],
        datasets: [
          {
            backgroundColor: ["#6BB66D","#FF5733","#FFA726"],
            hoverBackgroundColor: ["#7CD37E","#FE7254","#FFB74D"]
          }
        ]
      },
      lightOptions: {
        responsive: false,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        }
      },
      chartDataPolar: {
        datasets: [{
          backgroundColor: ["#42A5F5","#66BB6A","#FFA726","#26C6DA","#7E57C2"],
          label: 'My dataset'
        }]
      },
      chartOptions: {
        responsive: false,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
      items: [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
      ],
      dataProcedureCharts: [],
      labelProcedureCharts: [],
      lucro: 0,
      periodos: [
        {
          name: "Este mês",
          periodo: 'MES'
        },
        {
          name: "Mês passado",
          periodo: 'MES_PASSADO'
        },
        {
          name: "90 dias atrás",
          periodo: '90_DIAS'
        }
      ],
      filters: {
        start: null,
        end: null,
        period: null
      }
    }
  },
  created() {
    moment.locale('pt-BR');
    this.dashboardService = new DashboardService();
  },
  mounted: async function () {
    const loader = this.$loading.show();
    await this.loadDashboard();
    loader.hide();
  },
  methods: {
    async loadDashboard() {
      const loader = this.$loading.show();
      let response = null;
      let start = null;
      let end = null;
      switch (this.filters.period) {
        case 'MES':
          start = moment().startOf('month').format('DD/MM/YYYY hh:mm');
          end = moment().endOf('month').format('DD/MM/YYYY hh:mm');
          break;
        case 'MES_PASSADO':
          start = moment().subtract(1,'month').startOf('month').format('DD/MM/YYYY hh:mm');
          end = moment().subtract(1, 'month').endOf('month').format('DD/MM/YYYY hh:mm');
          break;
        case '90_DIAS':
          start = moment().subtract(3,'month').startOf('month').format('DD/MM/YYYY hh:mm');
          end = moment().endOf('month').format('DD/MM/YYYY hh:mm');
          break;
        default:
          start = moment().startOf('month').format('DD/MM/YYYY hh:mm');
          end = moment().endOf('month').format('DD/MM/YYYY hh:mm');
          break;
      }

      if(this.filters.start && this.filters.end) {
        response = await this.dashboardService.dashboard(moment(this.filters.start).format('DD/MM/YYYY hh:mm'), moment(this.filters.end).format('DD/MM/YYYY hh:mm'));
      }else {
        response = await this.dashboardService.dashboard(start, end);
      }
      if(response.status === 200) {
        this.dataProcedureCharts = [];
        this.labelProcedureCharts = [];
        this.dashboard = response.data;
        this.chartData.datasets[0].data = [this.dashboard.schedulerFinishTotal, this.dashboard.schedulerCancelledTotal]

        this.dashboard.dashChartModels.forEach((procedureChart) => {
          this.dataProcedureCharts.push(procedureChart.total);
          this.labelProcedureCharts.push(procedureChart.name);
        });
        this.chartDataPolar.datasets[0].data = this.dataProcedureCharts;
        this.chartDataPolar.labels = this.labelProcedureCharts;
        this.profit();
      } else {
        this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Produto cadastrado com sucesso.', life: 3000});
      }
      loader.hide();
    },
    profit() {
      this.lucro = this.dashboard.income - this.dashboard.expenses;
    },

    productFault(product) {
      this.$router.push({name: 'produto', query: {id: product.id}});
    }
  }
}
</script>

<style scoped type="scss">

.disabled {
  pointer-events: none;
}

</style>
